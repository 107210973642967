

i.fa-solid {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
}

.navbar-brand-img {
    max-height: 5rem !important;
}

.navbar-horizontal .navbar-brand img {
    height: 72px !important;
}

.kd-bg {
    background-color: #FFCE34 !important;
}

.move-right {
    text-align: right;
}

.btn-nopad {
    padding: 0px !important;
    box-shadow: none !important;
    width: 100%;; 
  }

.alert-red {
    color: red;
}